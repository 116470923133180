import { SET_ERRMESSAGE, SET_INFOMESSAGE, SET_ACTIVEERROR, SET_ACTIVEINFO } from '@/constants'
const errorModule = {
  state: {
    infoMessage: '',
    errMessage: '',
    activeError: false,
    activeInfo: false
  },
  getters: {},
  mutations: {
    [SET_INFOMESSAGE]: (state, payload) => { state.infoMessage = payload },
    [SET_ERRMESSAGE]: (state, payload) => { state.errMessage = payload },
    [SET_ACTIVEERROR]: (state, payload) => { state.activeError = payload },
    [SET_ACTIVEINFO]: (state, payload) => { state.activeInfo = payload }
  },
  actions: {
    setErrMessage: ({ commit }, payload) => {
      commit(SET_ERRMESSAGE, payload)
    },
    setInfoMessage: ({ commit }, payload) => {
      commit(SET_INFOMESSAGE, payload)
    },
    setActiveError: ({ commit }, payload) => {
      commit(SET_ACTIVEERROR, payload)
    },
    setActiveInfo: ({ commit }, payload) => {
      commit(SET_ACTIVEINFO, payload)
    },
    openErrorPopup: ({ commit }, payload) => {
      commit(SET_ERRMESSAGE, payload)
      commit(SET_ACTIVEERROR, true)
      setTimeout(function () { commit(SET_ACTIVEERROR, false) }, 3000)
    },
    openInfoPopup: ({ commit }, payload) => {
      commit(SET_INFOMESSAGE, payload)
      commit(SET_ACTIVEINFO, true)
      setTimeout(function () { commit(SET_ACTIVEINFO, false) }, 3000)
    }
  }
}

export {
  errorModule
}
