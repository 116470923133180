import { ApiService } from '@/utils'
import { apiConstant } from '@/constants'

const client = new ApiService({})

export const registerCreateUser = (username, password, prefix, firstname, lastname, birthday, phone, email, pasword, password_confirmation) =>
  client.post(apiConstant.REG_CREATE, {
    username, 
    password, 
    prefix, 
    firstname, 
    lastname, 
    birthday, 
    phone, 
    email, 
    pasword, 
    password_confirmation
  })
export const registerVerifyOtp = async (mobile, otp) => {
  try {
    return await client.post(apiConstant.REG_VERIFY_OTP, {
      mobile,
      otp
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const authenService = async (username, password) => {
  try {
    return await client.post(apiConstant.LOGIN, {
      username,
      password
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const getUserProfileInfo = async () => {
  try {
    return await client.get(apiConstant.PROFILE)
  } catch (error) {
    throw new Error(error)
  }
}

export const updateUserProfileService = async (payload) => {
  try {
    return await client.post(apiConstant.PROFIL_UPDATE, payload)
  } catch (error) {
    throw new Error(error)
  }
}
