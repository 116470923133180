export const localStorageService = (() => {
  let _service

  function _getService () {
    if (!_service) {
      _service = this
      return _service
    }
    return _service
  }

  function _setToken (tokenObj, username, password) {
    localStorage.setItem('access_token', tokenObj?.data?.access_token)
    localStorage.setItem('refresh_token', tokenObj?.data?.refresh_token)
    localStorage.setItem('username', username)
    localStorage.setItem('password', password)
  }

  function _setTokenDefault (tokenObj) {
    localStorage.setItem('access_token', tokenObj?.data?.access_token)
    localStorage.setItem('refresh_token', tokenObj?.data?.refresh_token)
    localStorage.setItem('client_id', tokenObj?.data?.client_id)
    localStorage.setItem('game_permission', tokenObj?.data?.game_permission)
  }

  function _isHasToken () {
    return !!(
      window?.localStorage?.getItem('access_token') &&
      window?.localStorage?.getItem('refresh_token')
    )
  }

  function _getAccessToken () {
    return localStorage.getItem('access_token')
  }

  function _getClientId () {
    return localStorage.getItem('client_id')
  }

  function _getRefreshToken () {
    return localStorage.getItem('refresh_token')
  }

  function _getUsername () {
    return localStorage.getItem('username')
  }

  function _getPassword () {
    return localStorage.getItem('password')
  }

  function _clearToken () {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('client_id')
  }

  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken,
    isHasToken: _isHasToken,
    getUsername: _getUsername,
    getPassword: _getPassword,
    getClientId: _getClientId,
    setTokenDefault: _setTokenDefault,
  }
})()
