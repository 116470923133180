import { localStorageService } from '@/utils'
import {
  SET_TOKEN,
  SET_ISMEMBER,
  CLEAR_SESSION,
  STATUS_SUCCESS,
  SET_REGISTER_STEP
} from '@/constants'
import { authenService } from '@/services'
const authModule = {
  state: {
    isMember: localStorageService.isHasToken(),
    registerSetp: 1
  },
  mutations: {
    [SET_TOKEN]: (_, payload) => {
      localStorageService.setToken(
        payload.payload,
        payload.username,
        payload.password
      )
    },
    [SET_ISMEMBER]: (state, payload) => {
      state.isMember = payload
    },
    [SET_REGISTER_STEP]: (state, payload) => {
      state.registerSetp = payload
    },
    [CLEAR_SESSION]: () => {
      localStorageService.clearToken()
    }
  },
  getters: {
    getIsMember: state => state.isMember,
  },
  actions: {
    
    loginAction: async ({ commit }, { username, password }) => {
      try {
        const resp = await authenService(username, password)
        console.log(resp)
        if(resp){
          if (resp.status === STATUS_SUCCESS) {
            commit(SET_ISMEMBER, true) //TODO: for test
            commit(SET_TOKEN, { payload: resp, username, password }) //TODO: for test
            return true
          } else {
            return false
          }
        }else{
          return false
        }
      } catch (err) {
        console.error(`im err : ${err}`)
        return false
      }
    },
    setRegisterSetp: ({ commit }, payload) => {
      commit(SET_REGISTER_STEP, payload)
    },

    setToken: ({ commit }, payload) => {
      commit(SET_TOKEN, payload)
    },
    setIsMember: ({ commit }, payload) => {
      commit(SET_ISMEMBER, payload)
    },
    clearLogOut: ({ commit }) => {
      commit(SET_ISMEMBER, false)
      commit(CLEAR_SESSION)
    }
  }
}

export { authModule }
