import { SET_FAQLIST, STATUS_SUCCESS, STATUS_OK } from '@/constants'
import { getFaqListService, } from '@/services'

const faqModule = {
  state: {
    faqList: [],
  },
  getters: {
    faqList: (state) => state.faqList
  },
  mutations: {
    [SET_FAQLIST]: (state, payload) => {
      state.faqList = payload
    },
  },
  actions: {
    getFaqListApi: async ({ commit }, { limit }) => {
      try {
        const resp = await getFaqListService(limit)
        if (resp.status === STATUS_SUCCESS || resp.status === STATUS_OK) {
          commit(SET_FAQLIST, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}

export {
  faqModule
}
