import axios from 'axios'
import { localStorageService } from './localStorage'
import { apiConstant } from '@/constants'
import store from '@/store'
// import encryption from '@/utils/'
const API_ROOT = process.env.VUE_APP_API_URL
const TIMEOUT = 60000
const HEADERS = {
  'Content-Type': 'application/json'
}

export class ApiService {
  constructor ({ baseURL = API_ROOT, timeout = TIMEOUT, headers = HEADERS }) {
    const instance = axios.create({
      baseURL,
      timeout,
      headers,
      withCredentials: false
    })
    this.client = instance
    instance.interceptors.request.use(
      this.handleRequest,
      this.handleRequestError
    )
    instance.interceptors.response.use(this.handleSuccess, this.handleError)
  }

  async handleRequest (request) {
    const token = localStorageService.getAccessToken()
    // if (request is encrypt === 'Y') {
    // request.data = { data: encryption.encrypt(request.data) }
    // }
    if (token) {
      request.headers.Authorization = 'Bearer ' + token
    }
    return request
  }

  async handleRequestError (error) {
    localStorageService.clearToken()
    window.location.reload()
    Promise.reject(error)
  }

  handleSuccess (response) {
    if (response.data.status_code !== 'SUCCESS') {
      store.dispatch('openErrorPopup', response.data.status_msg)
    }
    return response
  }

  async handleError (error) {
    const originalRequest = error.config
    if (error.response.status === 400) {
      originalRequest._retry = true
      return axios
        .create({
          baseURL: `${process.env.VUE_APP_API_URL}`,
          timeout: TIMEOUT,
          headers: HEADERS,
          withCredentials: false
        })
        .post(apiConstant.REFRESH_TOKEN, {
          refresh_token: localStorageService.getRefreshToken(),
          client_id: localStorageService.getClientId(),
          username: localStorageService.getUsername(),
          password: localStorageService.getPassword(),
          grant_type: 'refresh_token'
        })
        .then(async res => {
          if (res.status_code !== 'SUCCESS') {
            await localStorageService.clearToken()
            window.location.reload()
            return
          }
          localStorageService.setTokenDefault(res?.data)
          axios.defaults.headers.common.Authorization =
            'Bearer ' + localStorageService.getAccessToken()
          return await axios(originalRequest)
        })
        .catch(async err => {
          await localStorageService.clearToken()
          window.location.reload()
          Promise.reject(err)
        })
    }
  }

  async get (path) {
    return this.client.get(path).then(response => response?.data)
  }

  async post (path, payload) {
    return this.client.post(path, payload).then(response => {
      return response?.data
    })
  }

  async put (path, payload) {
    return this.client.put(path, payload).then(response => response?.data)
  }

  async patch (path, payload) {
    return this.client.patch(path, payload).then(response => response?.data)
  }

  async delete (path) {
    return this.client.delete(path).then(response => response?.data)
  }
}
