import { ApiService } from '@/utils'
import { apiConstant } from '@/constants'

const client = new ApiService({})

export const getFaqListService = async (limit) => {
  try {
    return await client.get(apiConstant.FAQ_LIST, {
      limit
    })
  } catch (error) {
    throw new Error(error)
  }
}
