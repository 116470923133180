import {
  STATUS_SUCCESS,
  SET_EMAIL,
  SET_REGISTERFLAG
} from '@/constants'
import { registerVerifyOtp, registerCreateUser } from '@/services'
import { localStorageService } from '@/utils'
const registerModule = {
  state: {
    email: '',
    registerFlag: false,
  },
  mutations: {
    [SET_EMAIL]: (state, payload) => {
      state.email = payload
    },
    [SET_REGISTERFLAG]: (state, payload) => {
      state.registerFlag = payload
    }
  },
  getters: {},
  actions: {
    verifyEmail: async ({ commit }, email) => {
      try {
        const resp = await registerVerifyOtp(email)
        if (resp.status_code === STATUS_SUCCESS) {
          commit(SET_EMAIL, email)
          return true
        } else {
          return false
        }
      } catch (err) {
        console.error(`im err : ${err}`)
        return err
      }
    },
    registerCreateUser: async ({ commit },
      { username, password, prefix, firstname, lastname, birthday, phone, email, pasword, password_confirmation }) => {
      try {
        const resp = await registerCreateUser(username, password, prefix, firstname, lastname, birthday, phone, email, pasword, password_confirmation)
        if (resp.status_code === STATUS_SUCCESS) {
          localStorageService.setToken(null, username, password)
          commit(SET_REGISTERFLAG, true)
          return true
        } else {
          return false
        }
      } catch (err) {
        console.error(`im err : ${err}`)
        return err
      }
    }
  }
}

export { registerModule }
