import Vue from 'vue'
import Vuex from 'vuex'
import {
  homeModule,
  userModule,
  authModule,
  registerModule,
  errorModule,
  knowledgeModule,
  faqModule
} from '@/store/modules'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    homeModule,
    userModule,
    authModule,
    registerModule,
    errorModule,
    knowledgeModule,
    faqModule
  }
})
